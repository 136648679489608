.blog-page {
    width: 100%;
    height: auto;
    padding-top: 110px;
    padding-bottom: 150px;
    font-family: 'Playfair Display', serif;
}

.blog-page .left-side h2 {
    color: #959595;
    text-align: left;
    font-size: 36px;
    padding-bottom: 30px;
}

.blog-page .left-side figure .figure-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    position: relative;
}

.blog-page .left-side figure figcaption {
    position: absolute;
    top: 0;
    right: 15px;
}

.blog-page .left-side figcaption img {
    width: 63px;
}

.blog-page .left-side figcaption span {
    position: absolute;
    top: 0;
    right: 15px;
    color: #ffffff;
}

.blog-page .left-side p {
    font-size: 16px;
    color: #969696;
    font-family: 'Playfair Display', serif;
}

.blog-page .left-side h4 {
    font-size: 22px;
    padding-bottom: 20px;
}

.blog-page .left-side h4 a:hover {
    color: #000000;
}

.blog-page .left-side .btn-danger,
.blog-page .left-side .btn-primary {
    border-radius: 0;
    font-size: 14px;
    letter-spacing: 0.5px;
    padding: 8px 15px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.blog-page .left-side .btn-danger,
.blog-page .left-side .btn-danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #00aeef;
    border-color: transparent;
}

.blog-page .btn-group a:hover {
    color: #ffffff;
}

.blog-page .left-side .btn-primary,
.blog-page .left-side .btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #363636;
    border-color: #363636;
    font-family: 'Montserrat', sans-serif;
}

.blog-page .left-side .btn-primary .fa {
    font-size: 16px;
}

.blog-page .right-side {
    border: 1px solid #d4d4d4;
    padding: 40px 40px
}

.blog-page .right-side figure {
    margin: 0 0 1.5rem;
}

.blog-page .right-side h3 {
    font-size: 32px;
    font-weight: 400;
    padding-bottom: 20px;
}

.blog-page .right-side a .fa {
    font-size: 30px;
    margin-right: 10px;
    margin-bottom: 15px;
}

.blog-page .right-side address {
    padding-bottom: 10px;
}

.blog-page .right-side .fa-facebook-square {
    color: #0054a6;
}

.blog-page .right-side .fa-twitter-square {
    color: #0054a6;
}

.blog-page .right-side .fa-youtube-square {
    color: #ed1c24;
}

.blog-page .right-side .fa-instagram {
    color: #8c6239;
}

.blog-page .right-side .fa-dribbble {
    color: #39b44a;
}

.blog-page .right-side .fa-pinterest-square {
    color: #ed145a;
}

.blog-page .right-side address span {
    font-size: 16px;
    letter-spacing: 0.5px;
}

.blog-page .right-side h2 {
    font-size: 29px;
    padding-bottom: 20px;
}

.blog-page .right-side h5 {
    font-size: 16px;
    line-height: 28px;
    padding-bottom: 20px;
}

.blog-page .right-side figure img {
    width: 100%;
    height: 200px;
}

.blog-page .right-side .form-control {
    border-radius: 0;
    padding: 10px 10px;
}

.blog-page .right-side address span {
    display: block;
}

.blog-page .form-control:focus {
    box-shadow: none;
}

.blog-page .right-side .btn-primary {
    width: 100%;
    border-radius: 0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding: 10px 0;
    text-transform: capitalize;
}

.blog-page .right-side .btn-primary:not(:disabled):not(.disabled):active,
.blog-page .right-side .btn-primary:hover {
    color: #000;
    background-color: #f9e43f;
    border-color: #f9e43f;
}

.blog-page .bt {
    padding-top: 50px;
}

.blog-page .bt ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
}

.blog-page .bt ul li {
    background: transparent;
    border: 1px solid #d4d4d4;
    display: inline-flex;
    text-align: center;
    margin-right: 5px;
}

.blog-page .bt .active {
    background-color: #00aeef;
    border: 1px solid #00aeef;
    color: white;
}

.blog-page .bt a:hover {
    background-color: #00aeef;
    border: 1px solid #00aeef;
    color: white;
}

.blog-page .bt ul li a {
    width: 90px;
    height: 40px;
    color: #ffffff;
    margin: 0 auto;
    font-size: 16px;
    background-color: #363636;
    padding: 7px 0;
    font-weight: 600;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
}