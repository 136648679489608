.support .main {
    width: 770px;
}

.support {
    padding-top: 120px;
    padding-bottom: 200px;
    text-align: center;
}

.support h3 {
    color: #959595;
    font-size: 36px;
    padding-bottom: 50px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Playfair Display', serif;
}

.support p {
    color: #707070;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 20px;
    text-align: left;
    font-family: 'Playfair Display', serif;
}

.support p a {
    color: #707070;
    border-bottom: 1px solid #707070;
}

.support .figure {
    width: 100%;
    height: auto;
}

.support .figure img {
    width: 100%;
    object-fit: cover;
}

.support .text-left {
    padding-bottom: 80px;
}

.support select {
    width: 100%;
    height: 52px;
    color: #d3d0d0;
    font-size: 12px;
    padding: 0 8px;
    cursor: pointer;
    margin-bottom: 10px;
}

.support .btn-primary {
    color: #fff;
    width: 260px;
    font-size: 14px;
    text-align: center;
    background-color: #000;
    border-color: #000;
    height: 50px;
    border-radius: 0;
    text-transform: uppercase;
}

.support .btn-primary:hover {
    color: #000;
    background-color: transparent;
    border-color: #000;
}

.support .form-control {
    height: 52px;
    border-radius: 0;
    margin-bottom: 25px;
    border: 1px solid #acacac;
}

.support .form-group {
    margin-bottom: 0;
}

.support textarea.form-control {
    height: auto;
    margin-bottom: 15px;
    padding: 20px 13px;
}

.support ::-webkit-input-placeholder {
    color: #b8b8b8;
    text-transform: capitalize;
    font-size: 14px;
}

.support ::-moz-placeholder {
    color: #b8b8b8;
    text-transform: capitalize;
    font-size: 14px;
}

.support ::-ms-input-placeholder {
    color: #b8b8b8;
    text-transform: capitalize;
    font-size: 14px;
}

.support ::-moz-placeholder {
    color: #b8b8b8;
    text-transform: capitalize;
    font-size: 14px;
}

label{
    font-weight: bold;
}

#errorAlert{
color: red;
}