footer {
    color: #ffffff;
    padding-top: 40px;
    background-image: url('./footer-bg-image.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    font-family: 'Playfair Display', serif;
}

footer .footer-top {
    padding-bottom: 60px;
}

footer .footer-bottom {
    padding-top: 30px;
    padding-bottom: 10px;
    text-align: center;
    background: rgba(0, 0, 0, 0.60);
}

footer .footer-bottom .hidden {
    padding: 0 10px;
}

footer .footer-bottom ul li a {
    font-size: 16px;
    letter-spacing: 1px;
    color: #ffffff;
}

footer .footer-bottom p {
    font-size: 16px;
    letter-spacing: 1px;
}

footer .footer-bottom p a {
    color: #fce000;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-decoration: underline;
}

footer .footer-bottom p span {
    padding: 10px;
}

footer .footer-top h2 {
    font-size: 36px;
    text-align: left;
    padding-bottom: 20px;
}

footer .img-thumbnail {
    padding: 0;
    border: none;
    border-radius: 0;
    height: 145px;
    object-fit: cover;
}

.d-block {
    position: relative;
    overflow: hidden;
}

.d-block img {
    max-width: 100%;

    -moz-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

.d-block:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

#return-to-top {
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    display: none;
    z-index: 9999;
    display: block;
    position: fixed;
    background: #f9e43f;
    text-decoration: none;
    -moz-border-radius: 35px;
    -webkit-border-radius: 35px;
    border-radius: 0;
    -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

#return-to-top i {
    color: #000000;
    margin: 0;
    position: relative;
    left: 20px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

#return-to-top:hover {
    background: #f9e43f;
}

#return-to-top:hover i {
    color: #000000;
    top: 11px;
    left: 20px;
    font-size: 20px;
}

::placeholder {
    font-size: 14px;
    font-style: italic;
}

:-moz-placeholder {
    font-size: 14px;
    font-style: italic;
}

:-ms-input-placeholder {
    font-size: 14px;
    font-style: italic;
}

::-moz-placeholder {
    font-size: 14px;
    font-style: italic;
}

::-webkit-input-placeholder {
    font-size: 14px;
    font-style: italic;
}